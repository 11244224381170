import React from 'react'
import Layout from "../components/layout"
import Helmet from 'react-helmet'
import Header from '../components/header'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Smile from '../images/smile.svg'
import Smilesmall from '../images/smile-small.svg'
import CirclesBg from '../images/circles-bg.svg'
import RecordIcon from '../images/record-video-icon.svg'
import GroupIcon from '../images/group-icon.svg'
import ShareIcon from '../images/share-video-icon.svg'
import BackgroundBoy from '../components/bgimage'
import BackgroundSisters from '../components/bgimagesisters'
import BackgroundGrandpa from '../components/bgimagegrandpa'
import Cards from '../components/cards'
import Footer from '../components/footer'

export default ({ data }) => (

  <Layout>
    <Helmet>
      <title>The most joyful memorial gift - Joycards</title>
      <meta name="description" content="Create a memorial group video card and capture the memories of friends and family. It's the perfect tribute and remembrance gift to capture people's memories of a loved one forever." />
    </Helmet>
    <Header />
    <section id='hero' role="main">
      <div className='container two-grid'>
        <div className='hero-left hero-text flex flex-col flex-jc'>
          <h1>
              Create joy with a <br /><span className='sexy-text'>group memorial video card</span><br />
          </h1>
          <p className='lead mt-0'>Joycards are online group video cards which provide the perfect way to capture memories from family and friends of a loved one who has passed. Collect all the video messages into one memorable, lasting remembrance gift that the family will cherish.</p>
          <div className='cta-btn-wrapper'>
            <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='btn btn-teal btn-lg cta-btn'>Start your memorial</a>
            <div className='hero-below-btn'>
              <span>It's free. No credit card required.</span>
            </div>
          </div>
        </div>
        <div className='hero-right'>
          <div className='hero-img-wrapper'>
            <div className='bg-circles-top'>
              <img src={CirclesBg} alt='circles for decoration' aria-hidden="true"/>
            </div>
            <div className='hero-img'>
              <Img fluid={data.file.childImageSharp.fluid}
              alt="group video card showing multiple people making videos for a birthday card" />
            </div>
            <div className='smile-img'>
              <img src={Smile} alt='smile icon' aria-hidden="true"/>
            </div>
            <div className='bg-circles-side'>
              <img src={CirclesBg} alt='circles for decoration' aria-hidden="true"/>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='beige-bg section-wrapper-lg'>
      <div className='container'>
        <div className='highlight-wrapper'>
          <h2 className='text-center'>Create your memorial video in 3 easy steps</h2>
          <p className='text-center lead'>Hearing the stories from family and friends about a loved one who has passed not only surfaces amazing memories but also warms the heart and reminds you how loved the person was. Joycards makes it easy to create a group memorial video card, that lasts forever.</p>
          <div className='flex flex-jc flex-wrap mt-2 text-center'>
            <div className='highlight'>
              <div className='highlight-head'>
                <img src={RecordIcon} alt='record video icon' aria-hidden="true"/>
              </div>
              <div className='highlight-body'>
                <h4 className='mt-1'>Record your video</h4>
                <p className='lead mt-05'>Record your own personal video message.</p>
              </div>
            </div>
            <div className='highlight'>
              <div className='highlight-head'>
                <img src={GroupIcon} alt='group of people icon' aria-hidden="true"/>
              </div>
              <div className='highlight-body'>
                <h4 className='mt-1'>Invite family &amp; friends</h4>
                <p className='lead mt-05'>Send invites to family and friends of your loved one so they can contribute to your remembrance video.</p>
              </div>
            </div>
            <div className='highlight'>
              <div className='highlight-head'>
                <img src={ShareIcon} alt='share video icon' aria-hidden="true"/>
              </div>
              <div className='highlight-body'>
                <h4 className='mt-1'>Share the joy</h4>
                <p className='lead mt-05'>When your card is ready, send it to the recipient and share some joy.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='beige-bg feature-wrapper'>
      <div className='container'>
        <div className='two-grid-features feature-box'>
          <div className='feature-img'>
            <BackgroundBoy />
          </div>
          <div className='feature-content flex flex-jc flex-ac flex-col'>
            <img src={Smilesmall} alt='smile icon' aria-hidden="true"/>
            <div className='feature-text'>
              <h3>One video card,<br />many videos</h3>
              <p>Create more memorable, and lasting, memorial gifts that people cherish.</p>
            </div>
            <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='feature-link'>Start your memorial video</a>
          </div>
        </div>

        <div className='two-grid-features grid-reversed feature-box feature-mid'>
          <div className='push'>
            <div className='feature-content flex flex-jc flex-ac flex-col'>
              <img src={Smilesmall} alt='smile icon' aria-hidden="true"/>
              <div className='feature-text'>
                <h3>Invite<br /> family and friends</h3>
                <p>Invite family and friends to contribute their video message to make a lasting group memorial that will be cherished forever.</p>
              </div>
              <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='feature-link'>Start your card</a>
            </div>
          </div>
          <div className='pull feature-img'>
            <BackgroundSisters />
          </div>
        </div>

        <div className='two-grid-features feature-box'>
          <div className='feature-img'>
            <BackgroundGrandpa />
          </div>
          <div className='feature-content flex flex-jc flex-ac flex-col'>
            <img src={Smilesmall} alt='smile icon' aria-hidden="true"/>
            <div className='feature-text'>
              <h3>More joy than a greeting card</h3>
              <p>Joycards last forever. They're personal, and loved. They are the perfect way to remember those that have passed.</p>
            </div>
            <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='feature-link'>Start your card</a>
          </div>
        </div>
      </div>
    </section>

    <section className='beige-bg section-wrapper-sm'>
      <div className='container'>
        <div className='sub-page-content'>
          <div className='qa'>
            <h5>What are group video memorials?</h5>
            <p>Joycards enables you to collate video messages from family and friends to create a memorial containing many video messages.</p>
            <p>It's not just for memorial! Joycards are used for <Link to="/birthday-card">birthdays</Link>, <Link to="/baby-shower-card">baby showers</Link>, farewells, get-well-soons and all other times you want to send a card that is more loved, and lasts longer, than a boring old greeting card or eCard.</p>
          </div>
          <div className='qa'>
            <h5>How does it work?</h5>
            <p><Link to="/how-to-1">Sign up for free</Link> and record your video. Then invite people to add their videos to the card. When you've received all the videos simply send your Joycard to the final recipient. If there isn't a final recipient that's ok. As the memorial creator you'll be able to watch all videos that are contributed. The contributors to your memorial video card will also be able to see all the videos.</p>
          </div>
          <div className='qa'>
            <h5>Is it free?</h5>
            <p>Joycards is currently free to use. We will add new paid features in future but for now enjoy sending more joy for free <span role="img" aria-label="smiley face">😄</span>.</p>
          </div>
          <div className='qa'>
            <h5>How many people can contribute to my remembrance video card?</h5>
            <p>You can have up to 50 people contribute a video to your Joycard.</p>
          </div>
          <div className='qa'>
            <h5>How long are the videos?</h5>
            <p>The maximum length of a video is 1 minute. However, we find videos between 30 - 60 seconds work best as part of a group video card. We'll be increasing this limit soon with our paid features.</p>
          </div>
          <div className='qa'>
            <h5>Can I save my videos?</h5>
            <p>Your videos will always be available in your account. Plus, if the recipient of your Joycard chooses to open an account they will have access to their Joycard whenever they want to view it.</p>
          </div>
          <div className='qa'>
            <h5>Can this be used as a remembrance gift or remembrance card?</h5>
            <p>YES! It's the perfect way to group loved ones together to create a memorial that you can hand over to a grieving spouse, parent or family member.</p>
          </div>
        </div>
      </div>
    </section>

    <section className='cta'>
      <div className='container text-center'>
        <div className='flex flex-col'>
          <h5>Share some joy</h5>
          <div className='cta-btn-wrapper mt-1'>
            <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='btn btn-teal btn-lg cta-btn'>Start Your Memorial Video - Free</a>
          </div>
        </div>
      </div>
    </section>

    <Cards />
    <Footer />
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "group-video-card.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800 quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`